<script>
export default {
  name: "HorizonMenu",
  data() {
    return {};
  },
  props: ["horizonMenu", "game"],
  methods: {},
  mounted() {},
  computed: {
    chatroomSettings() {
      return this.$store.state.chatroomSettings;
    },
  },
  watch: {},
  inject: ["highlightHorizonMenu"],
};
</script>
<template>
  <nav
    id="horizon-menu"
    :class="_TEMPLATE"
  >
    <template v-if="_TEMPLATE==='template-1'">
      <ul>
        <li
          v-for="item in horizonMenu"
          @click="highlightHorizonMenu(horizonMenu,item)"
          class="ripple"
          :class="{ highlight:item.highlight }"
          v-if="item.tag!=='chatroom'"
        >
          <i
            class="iconfont"
            :class="item.icon"
          ></i>
          {{item.title}}
        </li>
        <li
          v-for="item in horizonMenu"
          @click="highlightHorizonMenu(horizonMenu,item)"
          class="ripple"
          :class="{ highlight:item.highlight }"
          v-if="item.tag==='chatroom'&&(chatroomSettings&&chatroomSettings.inlet_display==='1')"
        >
          <i
            class="iconfont"
            :class="item.icon"
          ></i>
          {{item.title}}
        </li>
      </ul>
    </template>
    <template v-if="_TEMPLATE==='template-2'">
      <ul>
        <li
          v-for="item in horizonMenu"
          @click="highlightHorizonMenu(horizonMenu,item)"
          class="ripple"
          :class="{ highlight:item.highlight }"
        >
          <img :src="require('@@/assets/images/tradingfloor/icons/'+ item.icon +'.png')" />
          {{item.title}}
        </li>
      </ul>
    </template>
  </nav>
</template>
<style lang='scss' scoped type="text/css">
#horizon-menu {
  border-bottom: 1px solid #d9d9d9;
  background-color: #ffffff;
  &.template-1 {
    ul {
      li {
        color: #666666;
        flex-basis: 16.6%;

        &.highlight {
          background-color: #e8eefd;
          color: #252525;
        }

        img {
          width: 0.8rem;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  &.template-2 {
    ul {
      li {
        color: #666666;
        flex-basis: 16.6%;

        &.highlight {
          background-color: #e8eefd;
          color: #252525;
        }

        img {
          width: 0.8rem;
          display: block;
          margin: 0 auto;
        }
      }
    }
  }

  ul {
    display: flex;
    list-style: none;

    li {
      flex-grow: 1;
      text-align: center;
      color: #666666;
      padding: 0.2rem 0;
      font-size: 0.24rem;

      i {
        font-size: 0.54rem;
        display: block;
      }

      &.highlight {
        color: #3985e1;
      }
    }
  }
}
</style>